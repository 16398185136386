import React from 'react'

import { CardHeader, OutlinedCard } from '../../styles/card.style'
import { ExerciseStyleOptions } from '../../styles/exerciseItem.style'
import { RecordsContainer } from '../../styles/records/records.style'
import { Exercise } from '../../types/schemas/emarking'
import StudentModuleMarksTable from '../tables/StudentModuleMarksTable'

const ModuleMarksCard = ({
  moduleCode,
  moduleTitle,
  exerciseSummaries,
  exerciseStyleOptions,
}: {
  moduleCode: string
  moduleTitle: string
  exerciseSummaries: Exercise[]
  exerciseStyleOptions: ExerciseStyleOptions
}) => {
  return (
    <OutlinedCard>
      <RecordsContainer records={'cardContent'}>
        <CardHeader>
          {moduleCode}: {moduleTitle}
        </CardHeader>
        {exerciseSummaries.length === 0 ? (
          <p>No exercises to show with the current filters</p>
        ) : (
          <StudentModuleMarksTable
            data={exerciseSummaries}
            exerciseStyleOptions={exerciseStyleOptions}
          />
        )}
      </RecordsContainer>
    </OutlinedCard>
  )
}

export default ModuleMarksCard
