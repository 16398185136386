import React, { useEffect, useState } from 'react'
import { Check } from 'react-bootstrap-icons'

import { endpoints } from '../../constants/endpoints'
import { usePhdStudent } from '../../hooks/phd.service'
import { AnchorButton, Button, Checkbox, Div, Indicator } from '../../styles/_app.style'
import { StudentMilestone } from '../../types/phds'
import Tooltip from '../tooltip/Tooltip'
import { TickOrCrossStatus } from './StatusIcons'

interface stageProps {
  studentLogin: string
  stage: StudentMilestone
}

export const RpcStageStudent = ({ studentLogin, stage }: stageProps) => {
  const {
    submitFile,
    milestoneReportUploaded,
    setMilestoneReportUploaded,
    patchStudentMutualAgreementForStudent,
  } = usePhdStudent()

  const [studentAgreed, setStudentAgreed] = useState<boolean>(false)

  useEffect(() => {
    setMilestoneReportUploaded(!!stage.dateSubmitted)
    setStudentAgreed(!!stage.studentMutualAgreement)
  }, [stage])

  const [currentFile, setCurrentFile] = useState<File>()

  const handleSave = () => {
    patchStudentMutualAgreementForStudent('rpc', studentAgreed)
  }

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentFile(selectedFiles?.[0])
  }

  const handleFileUpload = () => {
    if (!currentFile) return
    submitFile(studentLogin, 'rpc', currentFile, currentFile.name)
  }

  let link = endpoints.submitMilestoneFile('2324', studentLogin, 'rpc')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="item"
      >
        <div>Date Required: {stage.dateRequired}</div>
      </div>
      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          Date Student Report Submitted:{' '}
          <TickOrCrossStatus reportSubmitted={milestoneReportUploaded} />
        </div>
        {milestoneReportUploaded && (
          <Tooltip label={'View Submission'}>
            <AnchorButton href={`/external-resource?url=${link}`}>View</AnchorButton>
          </Tooltip>
        )}
      </div>

      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <label className="btn btn-default p-0">
          <input type="file" onChange={selectFile} />
        </label>

        <Button disabled={!currentFile} onClick={handleFileUpload}>
          Upload
        </Button>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          padding: '0.5rem',
        }}
      >
        <Checkbox
          disabled={studentAgreed}
          checked={studentAgreed}
          onCheckedChange={() => setStudentAgreed((v) => !v)}
        >
          <Indicator>
            <Check size={22} />
          </Indicator>
        </Checkbox>
        <span>Tick to confirm you have read the agreement</span>

        <Tooltip label={'Confirm your agreement'}>
          <Button onClick={handleSave}>Save</Button>
        </Tooltip>
      </div>

      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p>
          Approved by PhD Office: <TickOrCrossStatus reportSubmitted={!!stage.adminApproval} />
        </p>
      </div>
    </Div>
  )
}
