import { useContext, useEffect } from 'react'

import { ThemeContext } from '../contextManagers/theme.context'
import { Container } from '../styles/_app.style'
import { OutlinedCard } from '../styles/card.style'
import { CATE_THEME_ACCENT_NAME } from '../styles/theming/cateMode'
import { NO_ACCENT } from '../styles/theming/colourConstants'
import { ColourDisplayDiv, PreviewContainer, ThemesDiv } from '../styles/theming/colourTheme.style'
import { ACCENTS, SPECIAL_ACCENTS } from '../styles/theming/colours'
import { capitaliseFirstLetter } from '../utils'
import Modules from './Modules'

const ColourTheme = () => {
  const { theme, accent, colorScheme, setThemeAndAccent } = useContext(ThemeContext)

  // HACK: Force rerender of this page when the accent colour changes
  useEffect(() => {}, [accent])

  return (
    <Container css={{ gap: '1.5rem' }}>
      <OutlinedCard padding={'medium'} gap={'medium'}>
        <h4>Scientia Colour Theme</h4>
        <b>Theme</b>
        <ThemesDiv>
          <ColourDisplayDiv
            active={theme === 'system'}
            box={'rectangle'}
            textColour={colorScheme === 'light' ? 'dark' : 'light'}
            backgroundColour={colorScheme}
            onClick={() => setThemeAndAccent({ theme: 'system' })}
            title="System theme"
          >
            System
          </ColourDisplayDiv>
          <ColourDisplayDiv
            active={theme === 'dark'}
            box={'rectangle'}
            textColour={'light'}
            backgroundColour={'dark'}
            onClick={() => setThemeAndAccent({ theme: 'dark' })}
            title="Dark theme"
          >
            Dark
          </ColourDisplayDiv>
          <ColourDisplayDiv
            active={theme === 'light'}
            box={'rectangle'}
            textColour={'dark'}
            backgroundColour={'light'}
            onClick={() => setThemeAndAccent({ theme: 'light' })}
            title="Light theme"
          >
            Light
          </ColourDisplayDiv>
        </ThemesDiv>
        <b>Accent</b>
        <ThemesDiv>
          <ColourDisplayDiv
            box={'accentsSquare'}
            active={accent === NO_ACCENT || 'defaultBox'}
            onClick={() => setThemeAndAccent({ accent: NO_ACCENT })}
            css={{
              backgroundColor: '$sand1',
            }}
          />
          {Object.entries(ACCENTS).map(
            ([key, value], index) =>
              !SPECIAL_ACCENTS.includes(value.name) && (
                <ColourDisplayDiv
                  box={'accentsSquare'}
                  css={{
                    backgroundColor:
                      theme === 'dark' ? value.mainColors.dark : value.mainColors.light,
                  }}
                  active={accent === key}
                  onClick={() => setThemeAndAccent({ accent: value.name })}
                  key={index}
                  title={capitaliseFirstLetter(value.name)}
                />
              )
          )}
          <ColourDisplayDiv
            box={'accentsSquare'}
            css={{ backgroundColor: ACCENTS[CATE_THEME_ACCENT_NAME].mainColors.light }}
            active={accent === CATE_THEME_ACCENT_NAME}
            onClick={() => setThemeAndAccent({ theme: 'light', accent: CATE_THEME_ACCENT_NAME })}
            title="Feeling nostalgic?"
          >
            <img src="/assets/cateIcon.svg" alt="cateIcon" />
          </ColourDisplayDiv>
        </ThemesDiv>
      </OutlinedCard>
      <OutlinedCard padding={'medium'} gap={'medium'} spaceBottom>
        <h4>Preview</h4>
        <PreviewContainer>
          <Modules />
        </PreviewContainer>
      </OutlinedCard>
    </Container>
  )
}

export default ColourTheme
