import * as Collapsible from '@radix-ui/react-collapsible'
import { useContext, useState } from 'react'
import {
  Check,
  Dice5Fill,
  DoorClosedFill,
  GearWideConnected,
  Justify,
  MoonFill,
  PaletteFill,
  PeopleFill,
  SunFill,
  XLg,
} from 'react-bootstrap-icons'
import { useNavigate, useParams } from 'react-router-dom'

import { LINKS } from '../../constants/links'
import { globalGameEnabled, useGame } from '../../contextManagers/game.context'
import { ThemeContext } from '../../contextManagers/theme.context'
import useAuth from '../../hooks/auth.service'
import { ActionButton } from '../../styles/dialog.style'
import {
  CollapsibleNavTrigger,
  DropdownIcon,
  ExpandedNavContainer,
  ExpandedNavItem,
  ExpandedNavSectionHeader,
  ExternalExpandedNavItem,
  Header,
  IconTouchTargetExpander,
  Logo,
  Nav,
  ReactExpandedNavItem,
  ScientiaTitle,
  WebsiteTitle,
} from '../../styles/navigation.style'
import { CATE_THEME_ACCENT_NAME } from '../../styles/theming/cateMode'
import { capitaliseFirstLetter, formatShortYear, shortYear } from '../../utils'
import { YearSwitcher } from '../YearSwitcher'
import { GenericNavigationBar } from './types'

export const MobileNavigation: GenericNavigationBar = ({ reactRouterLinks }) => {
  const { accent, appliedTheme, setThemeAndAccent } = useContext(ThemeContext)
  const [linksOpenState, setLinksOpenState] = useState(false)
  const [settingsOpenState, setSettingsOpenState] = useState(false)

  // For Settings (currently duplicated from desktop)
  const { logoutUser } = useAuth()
  const { gameSettingOn, toggleGameSetting } = useGame()
  const { year } = useParams()
  const currentYear = shortYear()
  const navigate = useNavigate()

  const NAV_ICON_SIZE = 22

  return (
    <Header>
      <Nav>
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', width: '100%' }}>
          <Collapsible.Root
            open={linksOpenState}
            onOpenChange={(state) => {
              setSettingsOpenState(false)
              setLinksOpenState(state)
            }}
          >
            <CollapsibleNavTrigger asChild title="Show Navigation Menu">
              <DropdownIcon>
                {linksOpenState ? <XLg size={NAV_ICON_SIZE} /> : <Justify size={NAV_ICON_SIZE} />}
              </DropdownIcon>
            </CollapsibleNavTrigger>

            <ExpandedNavContainer>
              <ExpandedNavSectionHeader>Scientia Pages</ExpandedNavSectionHeader>
              {reactRouterLinks.map((link, index) => (
                <ReactExpandedNavItem
                  key={index}
                  {...link}
                  onClick={(event) => {
                    link.onClick && link.onClick(event)
                    setLinksOpenState(false)
                  }}
                >
                  <IconTouchTargetExpander>
                    <link.icon size={NAV_ICON_SIZE} />
                  </IconTouchTargetExpander>
                  <span>{link.title}</span>
                </ReactExpandedNavItem>
              ))}

              <ExpandedNavSectionHeader>External Links</ExpandedNavSectionHeader>
              {LINKS.map((link) => (
                <ExternalExpandedNavItem
                  href={link.url}
                  key={link.title}
                  target="_blank"
                  rel="noreferrer"
                  title={link.description}
                >
                  <IconTouchTargetExpander>
                    <link.icon size={NAV_ICON_SIZE} />
                  </IconTouchTargetExpander>
                  <span>{link.title}</span>
                </ExternalExpandedNavItem>
              ))}
            </ExpandedNavContainer>
          </Collapsible.Root>

          <ScientiaTitle to={'/'} title={`View ${'homePage'}`} css={{ flex: '1 0 0' }}>
            <Logo
              alt="Scientia logo"
              src="/assets/logo-light.svg"
              style={{ filter: `invert(${appliedTheme === 'dark' ? 1 : 0})` }}
            />
            {accent === CATE_THEME_ACCENT_NAME ? (
              <WebsiteTitle>
                <span style={{ textDecoration: 'line-through' }}>Scientia</span> CATe
              </WebsiteTitle>
            ) : (
              <WebsiteTitle>Scientia</WebsiteTitle>
            )}
          </ScientiaTitle>

          <Collapsible.Root
            open={settingsOpenState}
            onOpenChange={(state) => {
              setLinksOpenState(false)
              setSettingsOpenState(state)
            }}
          >
            <CollapsibleNavTrigger asChild title="User Preferences">
              <DropdownIcon>
                {settingsOpenState ? (
                  <XLg size={NAV_ICON_SIZE} />
                ) : (
                  <GearWideConnected size={NAV_ICON_SIZE} />
                )}
              </DropdownIcon>
            </CollapsibleNavTrigger>

            {/* NOTE: DUPLICATED with Navigation.tsx */}
            <ExpandedNavContainer>
              <ExpandedNavSectionHeader>User Preferences</ExpandedNavSectionHeader>
              <ExpandedNavItem
                onClick={() =>
                  setThemeAndAccent({ theme: appliedTheme === 'light' ? 'dark' : 'light' })
                }
                title={`Use a ${appliedTheme === 'light' ? 'dark' : 'light'} appearance`}
              >
                <IconTouchTargetExpander>
                  {appliedTheme === 'light' ? (
                    <MoonFill size={NAV_ICON_SIZE} />
                  ) : (
                    <SunFill size={NAV_ICON_SIZE} />
                  )}
                </IconTouchTargetExpander>
                <span>
                  {capitaliseFirstLetter(appliedTheme === 'light' ? 'dark' : 'light') + ' mode'}
                </span>
              </ExpandedNavItem>
              <ExpandedNavItem onClick={() => navigate('/' + currentYear + '/theme')}>
                <IconTouchTargetExpander>
                  <PaletteFill size={NAV_ICON_SIZE} />
                </IconTouchTargetExpander>
                <span>Colour Theme</span>
              </ExpandedNavItem>
              <ExpandedNavItem onClick={() => navigate(`/credits`)}>
                <IconTouchTargetExpander>
                  <PeopleFill size={NAV_ICON_SIZE} />
                </IconTouchTargetExpander>
                <span>Credits</span>
              </ExpandedNavItem>
              <ExpandedNavItem>
                <YearSwitcher />
                <div style={{ flex: '1 0 0' }} />
                {year !== currentYear && (
                  <ActionButton.Primary
                    style={{ padding: '0.25rem 0.4rem', marginRight: '0.5rem' }}
                    onClick={() => navigate('/' + currentYear + window.location.pathname.slice(5))}
                  >
                    Go to {formatShortYear()}
                  </ActionButton.Primary>
                )}
              </ExpandedNavItem>
              {globalGameEnabled && (
                <ExpandedNavItem onClick={toggleGameSetting}>
                  <IconTouchTargetExpander>
                    <Dice5Fill size={NAV_ICON_SIZE} />
                  </IconTouchTargetExpander>
                  <span style={{ flexGrow: 1 }}>Game Levels</span>
                  {gameSettingOn && <Check size={30} />}
                </ExpandedNavItem>
              )}
              <ExpandedNavItem onClick={logoutUser}>
                <IconTouchTargetExpander>
                  <DoorClosedFill size={NAV_ICON_SIZE} />
                </IconTouchTargetExpander>
                <span>Logout</span>
              </ExpandedNavItem>
            </ExpandedNavContainer>
          </Collapsible.Root>
        </div>
      </Nav>
    </Header>
  )
}
