import React, { createContext, useEffect, useRef, useState } from 'react'

import { fetchAccentClassName } from '../styles/theming/accentUtils'
import { NO_ACCENT } from '../styles/theming/colourConstants'

/** We use the term colour scheme to represent the state of browsers */
type ColorScheme = 'light' | 'dark'
/** This is user's preference */
type ThemeOption = 'system' | ColorScheme

type ThemeProviderType = {
  theme: ThemeOption
  accent: string
  colorScheme: ColorScheme
  appliedTheme: ColorScheme
  setThemeAndAccent({ theme, accent }: { theme?: ThemeOption; accent?: string }): void
}

const defaultTheme: ThemeProviderType = {
  theme: 'system',
  accent: NO_ACCENT,
  colorScheme: 'light',
  appliedTheme: undefined as never,
  // We shall never invoke this function outside the provider
  setThemeAndAccent: undefined as never,
}

export const ThemeContext = createContext<ThemeProviderType>(defaultTheme)

/** Retrieve values from local storage */
function getPreference(pref: 'theme'): ThemeOption
function getPreference(pref: 'accent'): string
function getPreference(pref: 'theme' | 'accent'): string {
  const value = window.localStorage.getItem(pref)
  if (pref === 'theme') return value === 'light' || value === 'dark' ? value : defaultTheme[pref]
  return value ?? defaultTheme[pref]
}

function setPreference<T extends 'theme' | 'accent'>(
  name: T,
  value: T extends 'theme' ? ThemeOption : string
) {
  window.localStorage.setItem(name, value)
}

/** Get browser's colour scheme */
const getColorScheme = (): ColorScheme => {
  const mediaQueryList = matchMedia('(prefers-color-scheme: dark)')
  const hasMediaQueryPreference = typeof mediaQueryList.matches === 'boolean'
  if (hasMediaQueryPreference) return mediaQueryList.matches ? 'dark' : 'light'
  // We assume browsers that does not support prefers-color-scheme are light
  return defaultTheme.colorScheme
}

/** We create a hook to track classes we have added and clear them as necessary */
const useHTMLClassList = () => {
  const classListRef = useRef<string[]>([])

  const html = document.documentElement

  const clearAndSetClassList = (newClassList: string[], animate = true) => {
    html.classList.remove(...classListRef.current)
    html.classList.add(...newClassList)

    if (animate) {
      /* Add and remove animation override for smooth theme transition */
      document.body.classList.add('animate')
      setTimeout(() => document.body.classList.remove('animate'), 1000)
    }
    classListRef.current = newClassList
  }

  return { clearAndSetClassList }
}

export const ThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const [colorScheme, setColorScheme] = useState<ColorScheme>(getColorScheme())
  const [theme, setTheme] = useState<ThemeOption>(getPreference('theme'))
  const [accent, setAccent] = useState(getPreference('accent'))
  const appliedTheme = theme === 'system' ? colorScheme : theme
  const { clearAndSetClassList } = useHTMLClassList()

  // Apply the new theme and accent if these values changes
  useEffect(
    () => clearAndSetClassList([fetchAccentClassName(accent, appliedTheme)]),
    [clearAndSetClassList, accent, appliedTheme]
  )

  // Save the theme and accent into local storage when they change
  useEffect(() => setPreference('theme', theme), [theme])
  useEffect(() => setPreference('accent', accent), [accent])

  // Check if the user changes browser's colour scheme
  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
      if (e.matches) setColorScheme('dark')
    })
    window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (e) => {
      if (e.matches) setColorScheme('light')
    })
  }, [])

  function setThemeAndAccent({ theme, accent }: { theme?: ThemeOption; accent?: string }) {
    if (theme) setTheme(theme)
    if (accent) setAccent(accent)
  }

  return (
    <ThemeContext.Provider value={{ theme, accent, colorScheme, appliedTheme, setThemeAndAccent }}>
      {children}
    </ThemeContext.Provider>
  )
}
