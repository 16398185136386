import { ItemIndicator } from '@radix-ui/react-dropdown-menu'
import { useContext } from 'react'
import {
  Check,
  Dice5Fill,
  DoorClosedFill,
  GearWideConnected,
  MoonFill,
  PaletteFill,
  PeopleFill,
  SunFill,
} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'

import { globalGameEnabled, useGame } from '../../../contextManagers/game.context'
import { ThemeContext } from '../../../contextManagers/theme.context'
import useAuth from '../../../hooks/auth.service'
import { CheckboxItem, DropdownIcon, Item, Separator } from '../../../styles/navigation.style'
import { capitaliseFirstLetter, shortYear } from '../../../utils'
import { YearSwitcher } from '../../YearSwitcher'
import NavDropdown from './NavDropdown'

const UserSettingsDropdown = () => {
  const { logoutUser } = useAuth()
  const { appliedTheme, setThemeAndAccent } = useContext(ThemeContext)
  const navigate = useNavigate()
  const currentYear = shortYear()
  const { gameSettingOn, toggleGameSetting } = useGame()

  return (
    <NavDropdown tooltipLabel={'Open user settings'} icon={<GearWideConnected size={22} />}>
      <Item
        onSelect={() => setThemeAndAccent({ theme: appliedTheme === 'light' ? 'dark' : 'light' })}
        title={`Use a ${appliedTheme === 'light' ? 'dark' : 'light'} appearance`}
      >
        <DropdownIcon>{appliedTheme === 'light' ? <MoonFill /> : <SunFill />}</DropdownIcon>
        <span>{capitaliseFirstLetter(appliedTheme === 'light' ? 'dark' : 'light') + ' mode'}</span>
      </Item>
      <Separator />
      <YearSwitcher />
      <Separator />
      <Item onClick={() => navigate('/' + currentYear + '/theme')} title={'Colour Theme'}>
        <DropdownIcon>
          <PaletteFill size={20} />
        </DropdownIcon>
        <span>Colour Theme</span>
      </Item>
      <Separator />
      <Item onClick={() => navigate(`/credits`)} title={'Credits'}>
        <DropdownIcon>
          <PeopleFill size={20} />
        </DropdownIcon>
        <span>Credits</span>
      </Item>
      <Separator />
      {globalGameEnabled && (
        <>
          <CheckboxItem checked={gameSettingOn} onCheckedChange={toggleGameSetting}>
            <Dice5Fill size={20} style={{ margin: '0 0.5rem' }} />
            <span style={{ flexGrow: 1 }}>Game Levels</span>
            <ItemIndicator>
              <Check size={30} />
            </ItemIndicator>
          </CheckboxItem>
          <Separator />
        </>
      )}
      <Item onSelect={logoutUser}>
        <DropdownIcon>
          <DoorClosedFill size={20} />
        </DropdownIcon>
        Logout
      </Item>
    </NavDropdown>
  )
}

export default UserSettingsDropdown
