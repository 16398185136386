import React, { useEffect, useRef, useState } from 'react'

import { endpoints } from '../../constants/endpoints'
import { usePhdStudent } from '../../hooks/phd.service'
import { AnchorButton, Button, Div } from '../../styles/_app.style'
import { StudentMilestone } from '../../types/phds'
import Tooltip from '../tooltip/Tooltip'
import { SubmissionStatus, TickOrCrossStatus } from './StatusIcons'

interface stageProps {
  studentLogin: string

  stage: StudentMilestone
}

export const EsaStageStudent = ({ studentLogin, stage }: stageProps) => {
  const { submitFile, milestoneReportUploaded, setMilestoneReportUploaded } = usePhdStudent()

  useEffect(() => {
    setMilestoneReportUploaded(!!stage.dateSubmitted)
  }, [stage])

  const [currentFile, setCurrentFile] = useState<File>()

  const selectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files as FileList
    setCurrentFile(selectedFiles?.[0])
  }

  const handleFileUpload = () => {
    if (!currentFile) return
    submitFile(studentLogin, 'esa', currentFile, currentFile.name)
  }

  let link = endpoints.submitMilestoneFile('2324', studentLogin, 'esa')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
        className="item"
      >
        <div>Date Required: {stage.dateRequired}</div>
        <div>
          <p>Submission Status: </p>
          <SubmissionStatus submissionStatus={stage.status} stageStatus={stage.status} />
        </div>
      </div>
      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          Date Student Report Submitted:{' '}
          <TickOrCrossStatus reportSubmitted={milestoneReportUploaded} />
        </div>
        {milestoneReportUploaded && (
          <Tooltip label={'View Submission'}>
            <AnchorButton href={`/external-resource?url=${link}`}>View</AnchorButton>
          </Tooltip>
        )}
      </div>

      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <label className="btn btn-default p-0">
          <input type="file" onChange={selectFile} />
        </label>

        <Button disabled={!currentFile} onClick={handleFileUpload}>
          Upload
        </Button>
      </div>

      <div
        style={{
          paddingBottom: '1em',
          paddingTop: '1em',
          paddingLeft: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p>
          Approved by PhD Office: <TickOrCrossStatus reportSubmitted={!!stage.adminApproval} />
        </p>
      </div>

      <Div
        css={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          border: '1px solid $sand8',
          borderRadius: '1rem',
        }}
      >
        <p style={{ marginBottom: '1em', textDecoration: 'underline' }}>Supervisor Feedback:</p>
        <div>{stage.adminApproval ? stage.supervisorFeedback : 'Not Available'}</div>
      </Div>

      <Div
        css={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          border: '1px solid $sand8',
          borderRadius: '1rem',
        }}
      >
        <p style={{ marginBottom: '1em', textDecoration: 'underline' }}>Assessor Feedback: </p>
        <div>{stage.adminApproval ? stage.assessorFeedback : 'Not available'}</div>
      </Div>
    </Div>
  )
}
