import { darkTheme, theme } from '../stitches.config'
import { NO_ACCENT } from './colourConstants'
import { ACCENTS } from './colours'
import type { ThemeGeneratee } from './types'

const basicThemes: ThemeGeneratee['radixThemes'] = {
  light: theme,
  dark: darkTheme,
}

/** Util: Retrieve className to use for an accent given accent & theme */
export const fetchAccentClassName = (
  accent: typeof NO_ACCENT | keyof typeof ACCENTS,
  theme: keyof ThemeGeneratee['radixThemes']
): string => (accent === NO_ACCENT ? basicThemes : ACCENTS[accent].radixThemes)[theme].className
