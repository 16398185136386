import React, { useContext, useMemo, useState } from 'react'
import { Check } from 'react-bootstrap-icons'

import { endpoints } from '../../constants/endpoints'
import { PhDAdminUsers } from '../../constants/phdTracking'
import { ThemeContext } from '../../contextManagers/theme.context'
import { useUser } from '../../contextManagers/user.context'
import { usePhdStudent } from '../../hooks/phd.service'
import { AnchorButton, Button, Checkbox, Div, Indicator } from '../../styles/_app.style'
import { Form } from '../../styles/form.style'
import { StudentMilestone } from '../../types/phds'
import Tooltip from '../tooltip/Tooltip'
import { SubmissionStatus } from './StatusIcons'

function formatStringToDateTime(stringTime: string): string | null {
  return stringTime
    ? new Date(stringTime).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    : null
}

interface stageProps {
  studentLogin: string

  stage: StudentMilestone
}

export const RpcStage = ({ studentLogin, stage }: stageProps) => {
  const { adminApproveMilestone, patchSupervisorMutualAgreementForStudent } = usePhdStudent()

  const { userDetails } = useUser()

  const { theme } = useContext(ThemeContext)
  const [milestoneStatus, setMilestoneStatus] = useState<string>('')
  const [attemptDate, setAttemptDate] = useState('')
  const [supervisorAgreed, setSupervisorAgreed] = useState<boolean>(false)

  const initialPayload = useMemo(() => {
    setMilestoneStatus(stage.status)
    setAttemptDate(stage.attemptDate)
    setSupervisorAgreed(!!stage.staffMutualAgreement)
    return {
      status: stage.status,
      supervisorFeedback: stage.supervisorFeedback,
      assessorFeedback: stage.assessorFeedback,
      attemptDate: stage.attemptDate,
    }
  }, [stage])

  const handleAdminApproval = () => {
    adminApproveMilestone(studentLogin, 'rpc', true)
  }

  const handleSave = () => {
    patchSupervisorMutualAgreementForStudent(studentLogin, 'rpc', supervisorAgreed)
  }

  const isPhdAdmin = PhDAdminUsers.includes(userDetails!.login)

  let link = endpoints.submitMilestoneFile('2324', studentLogin, 'rpc')

  return (
    <Div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
        border: '1px solid $sand8',
        borderRadius: '1rem',
        padding: '1rem',
        width: '50%',
      }}
    >
      {isPhdAdmin && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '1em',
            alignItems: 'center',
          }}
        >
          <span>PhD Admin Approval</span>
          <Form
            onSubmit={(event) => {
              event.preventDefault()
              handleAdminApproval()
            }}
          >
            <Tooltip label={'Approve milestone'}>
              <Button>Approve milestone</Button>
            </Tooltip>
          </Form>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '1em',
          alignItems: 'center',
        }}
      >
        <span>Date Required: {formatStringToDateTime(stage.dateRequired)}</span>
        <SubmissionStatus submissionStatus={milestoneStatus} stageStatus={milestoneStatus} />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '1em',
        }}
      >
        <span>Date Submitted: {formatStringToDateTime(stage?.dateSubmitted!)}</span>
        <Tooltip label={'View Submission'}>
          <AnchorButton href={`/external-resource?url=${link}`}>View</AnchorButton>
        </Tooltip>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
          padding: '0.5rem',
        }}
      >
        <Checkbox
          disabled={supervisorAgreed}
          checked={supervisorAgreed}
          onCheckedChange={() => setSupervisorAgreed((v) => !v)}
        >
          <Indicator>
            <Check size={22} />
          </Indicator>
        </Checkbox>
        <span>Tick to confirm you have read the agreement</span>

        <Tooltip label={'Confirm your agreement'}>
          <Button onClick={handleSave}>Save</Button>
        </Tooltip>
      </div>

      <div
        style={{
          padding: '1em',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <span>Approved by PhD Office:</span>
        <span>{formatStringToDateTime(stage?.adminApproval!)} </span>
      </div>
    </Div>
  )
}
