import {
  ExpandedState,
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useState } from 'react'
import {
  CaretDownFill,
  CaretUpFill,
  Check2Circle,
  ExclamationCircleFill,
} from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'

import { Table, Td, Th, ThContainer } from '../../styles/table.style'
import { PhdStudentDetailMilestones } from '../../types/phds'

const StatusFlag = ({ flag }: { flag: string | undefined }) => {
  const props = { size: 22, title: flag }
  const FlagOK = <Check2Circle color="green" {...props} />
  const FlagLate = <ExclamationCircleFill color="red" {...props} />

  return <div>{flag === 'OK' ? FlagOK : FlagLate}</div>
}

export const PhdStudentsTable = ({
  phdStudents,
}: {
  phdStudents: PhdStudentDetailMilestones[]
}) => {
  const columnHelper = createColumnHelper<PhdStudentDetailMilestones>()
  const columns = [
    columnHelper.accessor('login', {
      cell: (info) => (
        <Link to={`${info.getValue()}`} title={`Details`}>
          {info.getValue()}
        </Link>
      ),
      header: () => 'Login',
      footer: (info) => info.column.id,
    }),

    columnHelper.accessor('cid', {
      cell: (info) => info.getValue(),
      header: () => 'CID',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor('firstName', {
      cell: (info) => info.getValue(),
      header: () => 'First Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.lastName, {
      id: 'lastName',
      cell: (info) => info.getValue(),
      header: () => 'Last Name',
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.supervisors, {
      id: 'supervisorName',
      cell: (info) => (
        <div>
          {info
            .getValue()
            .map((s) => `${s.firstName} ${s.lastName}`)
            .join(' ')}
        </div>
      ),
      header: () => 'Supervisor(s)',
      footer: (info) => info.column.id,
    }),

    // temp removal until best plan for this is agreed

    // columnHelper.accessor((row) => row.lastSubmissionDetails, {
    //   id: 'lastSubmissionDetails',
    //   cell: (info) => info.getValue(),
    //   header: () => 'Last Submission',
    //   footer: (info) => info.column.id,
    // }),
    // columnHelper.accessor((row) => row.status, {
    //   id: 'status',
    //   cell: (info) => <StatusFlag flag={info.getValue()} />,
    //   header: () => 'Current Status',
    //   footer: (info) => info.column.id,
    // }),
  ]

  const [expanded, setExpanded] = useState<ExpandedState>({})

  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data: phdStudents,
    columns: columns,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  return (
    <div style={{ overflowX: 'scroll' }}>
      <Table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, headerIndex) => (
                <Th key={header.id} expander={headerIndex === 0}>
                  {header.isPlaceholder ? null : (
                    <ThContainer
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <CaretUpFill />,
                        desc: <CaretDownFill />,
                      }[header.column.getIsSorted() as string] ?? null}
                    </ThContainer>
                  )}
                </Th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
