import { Expose, Type } from 'class-transformer'

import { endpoints } from '../constants/endpoints'

export class Supervisor {
  login: string
  @Expose({ name: 'firstname' })
  firstName: string
  @Expose({ name: 'lastname' })
  lastName: string

  @Expose({ name: 'email_address' })
  emailAddress: string

  role: string
}

export class PhDStaff {
  login: string
  @Expose({ name: 'full_name' })
  fullName: string
}

export class StudentMilestone {
  code: string
  name: string
  order: number

  @Expose({ name: 'date_required' })
  dateRequired: string

  @Expose({ name: 'date_submitted' })
  dateSubmitted: string | null

  @Expose({ name: 'assessor_feedback' })
  assessorFeedback: string | null

  @Expose({ name: 'supervisor_feedback' })
  supervisorFeedback: string | null

  @Expose({ name: 'feedback_available_to_student' })
  publishFeedback: string | null

  @Expose({ name: 'status' })
  status: string

  @Expose({ name: 'second_attempt_date' })
  attemptDate: string

  @Expose({ name: 'extension_approved' })
  extensionApproved: string | null

  @Expose({ name: 'admin_approval' })
  adminApproval: string | null

  @Expose({ name: 'staff_mutual_agreement' })
  staffMutualAgreement: string | null

  @Expose({ name: 'student_mutual_agreement' })
  studentMutualAgreement: string | null
}

export class PhdStudentDetailMilestones {
  cid: string
  login: string
  @Expose({ name: 'firstname' })
  firstName: string
  @Expose({ name: 'lastname' })
  lastName: string

  @Expose({ name: 'email_address' })
  emailAddress: string

  @Expose({ name: 'proposed_second_supervisor' })
  proposedSecondSupervisor: string | null

  @Expose({ name: 'proposed_assessor' })
  proposedAssessor: string | null

  @Expose({ name: 'studentstatus' })
  studentStatus: string

  @Expose({ name: 'start_date' })
  startDate: string

  @Expose({ name: 'end_date' })
  endDate: string

  @Type(() => Supervisor)
  supervisors: Supervisor[]

  @Expose({ name: 'milestone_submissions' })
  @Type(() => StudentMilestone)
  milestones: StudentMilestone[]

  @Expose({ name: 'last_submission' })
  lastSubmissionDetails: string | null

  @Expose({ name: 'status' })
  status: string

  @Expose({ name: 'main_supervisors' })
  supervisorName: string | null

  @Expose({ name: 'title_of_phd' })
  phdTitle: string
}

export class milestoneSubmission {
  student_login: string
  id: number

  @Expose({ name: 'file_path' })
  filePath: string
}

export interface MilestonePayload {
  status: string
}
