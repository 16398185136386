/**
 * NOTE: For Mac uses on macOS Monterey, the AirPlay Receiver service occupies port 5000. When running materials, please
 * disable it by opening System Preferences, navigating to Sharing and unchecking the AirPlay Receiver service.
 *
 * NOTE: To configure Scientia for testing on mobile devices over the local network, please add a new constant below
 * that's mapped to your device's IP address and temporarily update the baseURL to use this instead of development:
 * const local = 'http://X.X.X.X:5000'
 * Next, start materials (and/or emarking) with the --host 0.0.0.0 argument.
 *
 * TODO: Not verified after latest updates using docker for local development.
 */
export const baseURL = process.env.REACT_APP_API_ENTRYPOINT || 'http://localhost:8080/api'

export const endpoints = {
  login: `${baseURL}/auth/login`,
  logout: `${baseURL}/auth/logout`,
  refresh: `${baseURL}/auth/refresh`,
  personal: (year: string) => `${baseURL}/${year}/personal`,
  studentInfo: (year: string) => `${baseURL}/${year}/student-info`,
  tutorialGroups: (year: string) => `${baseURL}/${year}/tutorial-groups`,
  modules: (year: string) => `${baseURL}/${year}/modules`,
  module: (year: string, moduleCode: string) => `${baseURL}/${year}/modules/${moduleCode}`,
  enrolledStudents: (year: string, moduleCode: string) =>
    `${baseURL}/${year}/modules/${moduleCode}/enrolled`,
  myExercises: (year: string) => `${baseURL}/me/${year}/exercises`,
  emarkingExercises: (year: string, moduleCode: string) =>
    `${baseURL}/${year}/${moduleCode}/exercises`,
  exercisesForYear: (year: string) => `${baseURL}/${year}/exercises`,
  submissionsForYear: (year: string) => `${baseURL}/${year}/submissions`,
  emarkingExercise: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}`,
  deliverables: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/deliverables`,
  deliverable: (year: string, moduleCode: string, exerciseNumber: number, deliverableId: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/deliverables/${deliverableId}`,
  exerciseSpec: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${endpoints.emarkingExercise(year, moduleCode, exerciseNumber)}/spec`,
  exerciseSupplementaryFile: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${endpoints.emarkingExercise(year, moduleCode, exerciseNumber)}/supplementary`,
  exerciseModelAnswer: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${endpoints.emarkingExercise(year, moduleCode, exerciseNumber)}/model-answer`,
  emarkingExercisePersonal: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}`,
  studentExerciseSummaries: (year: string, studentUsername: string) =>
    `${baseURL}/${year}/${studentUsername}/exercise-summaries`,
  submissions: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions`,
  submissionsZipped: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions/zipped`,
  submissionZipped: (year: string, moduleCode: string, exerciseNumber: number, username: string) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions/${username}/zipped`,
  submissionsForStaff: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions/staff`,
  submission: (year: string, moduleCode: string, exerciseNumber: number, submissionId: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions/${submissionId}`,
  submissionFile: (
    year: string,
    moduleCode: string,
    exerciseNumber: number,
    submissionId: number
  ) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/submissions/${submissionId}/file`,
  workloadSurveys: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/workload_survey_responses`,
  workloadSurvey: (
    year: string,
    moduleCode: string,
    exerciseNumber: number,
    workloadResponseId: number
  ) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/workload_survey_responses/${workloadResponseId}`,
  extensions: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/extensions`,
  marks: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/marks`,
  mark: (year: string, moduleCode: string, exerciseNumber: number, username: string) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/marks/${username}`,
  markByID: (year: string, moduleCode: string, exerciseNumber: number, markID: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/marks/${markID}`,
  enrolledStudentsWithAvailability: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/modules/${moduleCode}/exercises/${exerciseNumber}/group-members-availability`,
  inviteMembers: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}/group/members`,
  groupMember: (year: string, moduleCode: string, exerciseNumber: number, memberUsername: string) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}/group/members/${memberUsername}`,
  membershipInvitation: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}/group-membership`,
  submissionGroup: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}/group`,
  resources: `${baseURL}/resources`,
  publicResources: (year: string) => `${baseURL}/${year}/public-resources`,
  publicResourceFile: (year: string, resourceId: number) =>
    `${baseURL}/${year}/public-resources/${resourceId}/file`,
  submissionGroups: `${baseURL}/groups`,
  resource: (id: number) => `${baseURL}/resources/${id}`,
  copyResources: (moduleCode: string) => `${baseURL}/${moduleCode}/resources/migrate`,
  resourcesComplete: `${baseURL}/resources/complete`,
  resourceFileToGet: (id: number, fileName: string) =>
    `${baseURL}/resources/${id}/file/${fileName}`,
  resourceFileToUpdate: (id: number) => `${baseURL}/resources/${id}/file`,
  resourcesArchive: `${baseURL}/resources/zipped`,
  periods: (year: string) => `${baseURL}/${year}/periods`,
  gameSetting: `${baseURL}/game/setting`,
  enrollments: (year: string) => `${baseURL}/${year}/enrollments`,
  players: `${baseURL}/game/players`,
  moduleCompletions: (year: string) => `${baseURL}/modules/${year}/completion/mean`,
  moduleDatedCompletions: (year: string) => `${baseURL}/modules/${year}/completion/dated`,
  personalDistributions: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/me/${year}/${moduleCode}/exercises/${exerciseNumber}/distributions`,
  distributions: (year: string, moduleCode: string, exerciseNumber: number) =>
    `${baseURL}/${year}/${moduleCode}/exercises/${exerciseNumber}/distributions`,
  collatedSubmissionFile: (distributionId: number, submissionId: number) =>
    `${baseURL}/distributions/${distributionId}/collated-submissions/${submissionId}/file`,
  zippedDistribution: (distributionId: number) =>
    `${baseURL}/distributions/${distributionId}/zipped`,
  newFeedback: (distributionId: number, submissionId: number) =>
    `${baseURL}/distributions/${distributionId}/collated-submissions/${submissionId}/feedback`,
  feedbackFile: (distributionId: number, feedbackId: number) =>
    `${baseURL}/distributions/${distributionId}/feedback/${feedbackId}/file`,
  feedback: (distributionId: number, feedbackId: number) =>
    `${baseURL}/distributions/${distributionId}/feedback/${feedbackId}`,
  distribution: (distributionId: number) => `${baseURL}/distributions/${distributionId}`,
  collatedSubmission: (distributionId: number) =>
    `${baseURL}/distributions/${distributionId}/collated-submissions`,
  feedbackBatchUpload: (distributionId: number, marker: string) =>
    `${baseURL}/distributions/${distributionId}/${marker}/feedback`,
  tutoringAllocations: (year: string) => `${baseURL}/${year}/personal-tutoring-allocations`,
  allStudents: (year: string) => `${baseURL}/${year}/all-students-list`,
  tutorialSessions: (year: string) => `${baseURL}/${year}/sessions`,
  tutorialSession: (year: string, sessionId: number) => `${baseURL}/${year}/sessions/${sessionId}`,
  phdStudentsForSupervisor: (year: string, supervisorLogin: string) =>
    `${baseURL}/${year}/phd-students?supervisor_login=${supervisorLogin}`,
  phdOneStudent: (year: string, student_login: string) =>
    `${baseURL}/${year}/phd-student/${student_login}`,
  milestoneCsv: () => `${baseURL}/milestone-csv`,
  supervisorsCsv: () => `${baseURL}/supervisors`,

  submitMilestoneFile: (year: string, studentLogin: string, milestoneCode: string) =>
    `${baseURL}/${year}/files/${studentLogin}/milestone/${milestoneCode}`,
  approveMilestone: (year: string, studentLogin: string, milestoneCode: string) =>
    `${baseURL}/${year}/approval/${studentLogin}/milestone/${milestoneCode}`,
  submitPhDFeedback: (year: string, studentLogin: string, milestoneCode: string) =>
    `${baseURL}/${year}/phd-students/${studentLogin}/milestones/${milestoneCode}`,
  submitMilestoneStatus: (year: string, studentLogin: string, milestoneCode: string) =>
    `${baseURL}/${year}/phd-students/${studentLogin}/milestones/${milestoneCode}/status`,
  phdTitle: (year: string) => `${baseURL}/${year}/phd-student/phdtitle`,
  phdSupervisors: (year: string) => `${baseURL}/${year}/phd-students/supervisors`,
  phdAssessor: (year: string, studentLogin: string, assessorLogin: string) =>
    `${baseURL}/${year}/phd-students/${studentLogin}/assessor/${assessorLogin}`,
  phdStaffRPCMutualAgreement: (year: string, studentLogin: string, milestoneCode: string) =>
    `${baseURL}/${year}/phd-students/${studentLogin}/milestones/${milestoneCode}/agree`,
  phdStudentRPCMutualAgreement: (year: string, milestoneCode: string) =>
    `${baseURL}/${year}/phd-students/milestones/${milestoneCode}/agree`,

  publicAllModules: (year: string) => `${baseURL}/${year}/public/modules`,
  profileImage: (year: string, username: string) => `${baseURL}/${year}/profile-images/${username}`,
  ownPersonalTutorMeetings: (year: string) => `${baseURL}/me/${year}/pt-meetings`,
  ownPersonalTutorMeeting: (year: string, id: number) => `${baseURL}/me/${year}/pt-meetings/${id}`,
  personalTutorMeetings: (year: string) => `${baseURL}/${year}/pt-meetings`,
  studentTutoringSessions: (year: string, studentUsername: string) =>
    `${baseURL}/${year}/${studentUsername}/tutoring-sessions`,
  search: () => `${baseURL}/search`,
  configuration: (year: string) => `${baseURL}/${year}/configuration`,
  configurationPeriods: (year: string) => `${baseURL}/${year}/configuration/periods`,
  configurationPeriod: (year: string, periodId: number) =>
    `${baseURL}/${year}/configuration/periods/${periodId}`,
  externalModulesOnOffer: (year: string) => `${baseURL}/${year}/on-offer/external-modules`,
  personalExternalModuleChoices: (year: string) => `${baseURL}/me/${year}/external-modules/choices`,
  externalModuleChoices: (year: string) => `${baseURL}/${year}/external-module-choices`,
  externalModuleChoice: (year: string, choiceId: number) =>
    `${baseURL}/${year}/external-module-choices/${choiceId}`,
  internalModulesOnOffer: (year: string) => `${baseURL}/${year}/on-offer/internal-modules`,
  personalInternalModuleChoices: (year: string) => `${baseURL}/me/${year}/internal-modules/choices`,
  personalInternalModuleChoice: (year: string, choiceId: number) =>
    `${baseURL}/me/${year}/internal-modules/choices/${choiceId}`,
  myResults: (year: string) => `${baseURL}/me/${year}/grades`,
}
