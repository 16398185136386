import { useMemo, useState } from 'react'
import { Check } from 'react-bootstrap-icons'

import { Checkbox, Indicator } from '../../styles/_app.style'
import { Footer } from '../../styles/exercise/exercise.style'
import { ExerciseStyleOptions } from '../../styles/exerciseItem.style'
import { TableDiv } from '../../styles/records/records.style'
import { Module } from '../../types/schemas/abc'
import { Exercise, ExerciseStatus } from '../../types/schemas/emarking'
import ModuleMarksCard from './ModuleMarksCard'

const FilterCheckbox = ({
  id,
  children,
  state,
  setState,
}: React.PropsWithChildren<{
  id: string
  state: boolean
  setState: React.Dispatch<React.SetStateAction<boolean>>
}>) => (
  <span style={{ marginLeft: '1rem', display: 'flex', alignItems: 'center' }}>
    <Checkbox id={id} checked={state} onCheckedChange={() => setState((v) => !v)}>
      <Indicator>
        <Check size={22} />
      </Indicator>
    </Checkbox>
    <label htmlFor={id} style={{ marginLeft: '.5rem' }}>
      {children}
    </label>
  </span>
)

const AllModulesCard = ({
  studentModules,
  moduleSummaries,
  exerciseStyleOptions,
}: {
  studentModules: Module[]
  moduleSummaries: Map<string, Exercise[]>
  exerciseStyleOptions: ExerciseStyleOptions
}) => {
  const [showUnassessed, setShowUnassessed] = useState(true)
  const [showFuture, setShowFuture] = useState(false)

  const sortedModules = useMemo(
    () => studentModules.sort((a, b) => a.code.localeCompare(b.code)),
    [studentModules]
  )

  const renderModules = (modules: Module[]) =>
    modules.map((module, moduleIndex) => (
      <ModuleMarksCard
        key={moduleIndex}
        moduleCode={module.code}
        moduleTitle={module.title}
        exerciseSummaries={
          moduleSummaries
            .get(module.code)
            ?.filter(
              ({ exerciseStatus, isAssessed }) =>
                (showFuture || exerciseStatus !== ExerciseStatus.NOT_STARTED) &&
                (showUnassessed || isAssessed)
            ) ?? []
        }
        exerciseStyleOptions={exerciseStyleOptions}
      />
    ))

  const moduleHasSummaries = ({ code }: Module) =>
    moduleSummaries.get(code) && moduleSummaries.get(code)?.length !== 0

  return (
    <TableDiv>
      <div style={{ width: '100%' }}>
        <span style={{ display: 'flex', float: 'right' }}>
          <FilterCheckbox id="showUnassessed" state={showUnassessed} setState={setShowUnassessed}>
            Show unassessed
          </FilterCheckbox>
          <FilterCheckbox id="showFuture" state={showFuture} setState={setShowFuture}>
            Show future
          </FilterCheckbox>
        </span>
      </div>
      {renderModules(sortedModules.filter(moduleHasSummaries))}
      {renderModules(sortedModules.filter((m) => !moduleHasSummaries(m)))}
      <Footer squished>
        Please note that the marks shown above do not constitute an official transcript.
      </Footer>
    </TableDiv>
  )
}

export default AllModulesCard
