import { Accordion, Content, Header, Item, Trigger } from '@radix-ui/react-accordion'
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { useUser } from '../../contextManagers/user.context'
import { usePhdStudent } from '../../hooks/phd.service'
import { Banner, Button, Container, Div } from '../../styles/_app.style'
import { Caret } from '../../styles/collapsible-list.style'
import {
  Control,
  Field,
  Form,
  Input,
  Label,
  LabelContainer,
  Message,
  Submit,
} from '../../styles/form.style'
import { StudentMilestone } from '../../types/phds'
import { EsaStageStudent } from './EsaStageStudent'
// import { LsrStageStudent } from './LsrStageStudent'
// import { PrrStageStudent } from './PrrStageStudent'
import { RpcStageStudent } from './RpcStageStudent'

export interface PhdDetailsPayload {
  phdTitle?: string
}

export const PhdStudentDetailsPersonal = () => {
  const { userDetails } = useUser()

  const { phdStudent, phdDetailLoading, updatePhdTitle } = usePhdStudent()

  const [phdTitle, setPhdTitle] = React.useState<string>(phdStudent?.phdTitle ?? '')

  useEffect(() => {
    setPhdTitle(phdStudent?.phdTitle ?? '')
  }, [phdStudent])

  if (!userDetails) return <Banner>Could not load your personal details.</Banner>

  if (userDetails.login !== phdStudent?.login!)
    return <Banner>You can only view your own tracking information</Banner>

  function applyPatch(key: keyof PhdDetailsPayload, value: PhdDetailsPayload[typeof key]) {
    if (key === 'phdTitle') {
      setPhdTitle(value ?? '')
    }
  }

  const expandedStatuses: string[] = ['ESA', 'RPC']

  const pageTitle: string = 'PhD Tracking'

  const stageComponent = (stageCode: string, stageMilestone: StudentMilestone) => {
    switch (stageCode) {
      case 'ESA': {
        return <EsaStageStudent stage={stageMilestone} studentLogin={phdStudent?.login!} />
      }

      case 'RPC': {
        return <RpcStageStudent studentLogin={phdStudent?.login!} stage={stageMilestone} />
      }
      // case 'LSR': {
      //   return (
      //       <LsrStageStudent
      //           studentLogin={phdStudent?.login!}
      //           assessorLogin={phdStudent?.assignAssessor!}
      //           secondSupervisorLogin={phdStudent?.assignSecondSupervisor!}
      //           stage={stageMilestone}
      //       />
      //   )
      //   break
      // }
      // case 'PRR': {
      //   return <PrrStageStudent stage={stageMilestone} studentLogin={phdStudent?.login!} />
      //   break
      // }
      default: {
        return <div>No milestone</div>
      }
    }
  }

  return (
    <Container expandX>
      {phdDetailLoading ? (
        <div>Loading your PhD details</div>
      ) : (
        <div>
          <Helmet>
            <title>{pageTitle}</title>
          </Helmet>

          <h1>{pageTitle}</h1>

          <Div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '0.5rem',
              border: '2px solid $sand8',
              borderRadius: '1rem',
              padding: '1rem',
              width: '50%',
              marginBottom: '2rem',
            }}
          >
            <Form
              css={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
              onSubmit={(event) => {
                event.preventDefault()
                updatePhdTitle(phdTitle)
              }}
            >
              <ul>
                <li>
                  Name: {phdStudent?.firstName} {phdStudent?.lastName}
                </li>
                <li>CID: {phdStudent?.cid}</li>
                <li>Login: {phdStudent?.login}</li>
                <li>Email: {phdStudent?.emailAddress}</li>

                <Field name="title">
                  <LabelContainer>
                    <Label>PhD Title</Label>
                    <Message match="valueMissing">Title required</Message>
                  </LabelContainer>
                  <Control asChild>
                    <Input
                      disabled={false}
                      type="text"
                      value={phdTitle}
                      onChange={({ target: { value } }) => applyPatch('phdTitle', value)}
                      required
                    />
                  </Control>
                </Field>
              </ul>

              <Submit asChild>
                <Button disabled={false}>Save PhD Title</Button>
              </Submit>
            </Form>
          </Div>

          <Accordion type="multiple" defaultValue={expandedStatuses}>
            {phdStudent?.milestones
              .filter((row) => row.code === 'RPC')
              .map((row, idx) => (
                <Item value={row.code} key={idx}>
                  <Header>
                    <span
                      style={{
                        fontSize: '0.8em',
                        width: '60%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        flexWrap: 'wrap',
                      }}
                    >
                      <span
                        style={{
                          flexBasis: '5%',
                        }}
                      >
                        <Trigger
                          style={{
                            backgroundColor: 'transparent',
                            background: 'transparent',
                            border: '0',
                          }}
                        >
                          <Caret />
                        </Trigger>
                      </span>
                      <span
                        style={{
                          flexBasis: '70%',
                        }}
                      >
                        {`${row.name} (${row.code})`}
                      </span>
                      <span
                        style={{
                          flexBasis: '25%',
                        }}
                      >
                        {/*<SubmissionStatusIcon*/}
                        {/*  submissionStatus={row.status}*/}
                        {/*  stageStatus={row.status}*/}
                        {/*></SubmissionStatusIcon>*/}
                      </span>
                    </span>
                  </Header>
                  <Content>{stageComponent(row.code, row)}</Content>
                </Item>
              ))}
          </Accordion>
        </div>
      )}
    </Container>
  )
}
